import { Post } from "./PortfolioContext";

export const fetchPosts = async (): Promise<Post[]> => {
  return fetch(
    `${process.env.REACT_APP_baseurl}/ghost/api/v4/content/posts/?key=${process.env.REACT_APP_contentKey}`,
    { mode: "no-cors" }
  )
    .then((response) => response.json())
    .then((response) => {
      let result = [];
      result = response?.posts;
      return result;
    })
    .catch((e) => {
      console.log(e);
    });
};
