import Image from "./Image";
import Content from "./Content";
import Link from "./Link";
import Projects from "./Projects";
import { PortfolioContext } from "./PortfolioContext";
import "./App.css";

function App() {
  return (
    <div className="App">
      <PortfolioContext>
        <Image />
        <Content />
        <Link />
        <Projects />
      </PortfolioContext>
    </div>
  );
}

export default App;
