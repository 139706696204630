import { Post } from "./PortfolioContext";

export const SortProjectOnDate = (posts: Post[]) => {
  let dateConvertedPost = [];
  dateConvertedPost = posts?.map(
    (post): Post => {
      let dates: Date[] = [];
      if (post.meta_description)
        dates = post.meta_description.split(",").map((item) => new Date(item));
      return {
        ...post,
        startEndDate: dates,
      };
    }
  );
  return dateConvertedPost?.sort((a, b) => {
    if (a.startEndDate.length > 0 && b.startEndDate.length > 0)
      if (a.startEndDate[0] < b.startEndDate[0]) return 1;
      else if (a.startEndDate[0] > b.startEndDate[0]) return -1;
      else return 0;
    return 0;
  });
};
