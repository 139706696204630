import React from "react";
import "./App.css";

function Image() {
  return (
    <div className="image-container">
      <img src="/profile.jpg" alt="" />
    </div>
  );
}

export default Image;
