import React from "react";
import { fetchPosts } from "./API";
import { SortProjectOnDate } from "./Mapper.util";

export type Post = {
  id: string;
  title: string;
  html: string;
  visibility: string;
  excerpt: string;
  meta_description: string;
  startEndDate: Date[];
  featured: boolean;
};

export enum Status {
  LOADING = "LOADING",
  ERROR = "ERROR",
  LOADED = "LOADED",
}

export type ContextState = {
  status: Status | "ERROR";
  value: Post[] | null;
};

export const useItemData = (): ContextState => {
  const contextState = React.useContext(Context);
  if (contextState === null) {
    throw new Error("useItemData must be used within a ItemDataProvider tag");
  }
  return contextState;
};

const Context = React.createContext<ContextState | null>(null);

export const PortfolioContext: React.FC = (props) => {
  const [state, setState] = React.useState<ContextState>({
    status: Status.LOADING,
    value: null,
  });

  React.useEffect(() => {
    setState({ status: Status.LOADING, value: null });

    (async (): Promise<void> => {
      const result = await fetchPosts();
      const mappedResult = SortProjectOnDate(result);
      if (mappedResult) {
        setState({
          status: Status.LOADED,
          value: mappedResult,
        });
      } else {
        setState({ status: Status.ERROR, value: null });
      }
    })();
  }, []);

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};
