import React from "react";
import "./App.css";

function Content() {
  return (
    <div className="content-container">
      <h1>Hi, I'm Amir Kiumarsi</h1>
      <div className="wrapper-one">
        <p>Freelancer software consultant and developer. Based in Amsterdam.</p>
        <p>
          Focus on e-commerce, JavaScript, TypeScript, Nodejs, Reactjs, Angular, Vuejs
        </p>
        <p>Terraform, Azure and CI/CD.</p>
        <p>Interested in AI and machine learning</p>
      </div>
      <div className="wrapper-one">
        <p>KvK: 75303248</p>
        <p>Btw: NL002502832B22</p>
      </div>
    </div>
  );
}

export default Content;
