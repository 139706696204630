import React from "react";

function Link() {
  return (
    <div className="link-container">
      <div className="wrapper-one">
        <div className="ico">
          <a href="mailto:amir-rt@live.com">
            <img src="/email.png" alt="" />
          </a>
        </div>
        <div className="ico">
          <a
            href="https://www.linkedin.com/in/amirkiumarsi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/linkedin.png" alt="" />
          </a>
        </div>
        <div className="ico">
          <a
            href="http://github.com/codacy20/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/github.png" alt="" />
          </a>
        </div>
      </div>
      <div className="wrapper-two">
        <a href="/AmirKiumarsiCV.pdf" target="_blank" rel="noopener noreferrer">
          <h3>Résumé</h3>
        </a>
        {/* <a href="/projects" target="_blank" rel="noopener noreferrer">
          <h3>Projects</h3>
        </a> */}
      </div>
    </div>
  );
}

export default Link;
