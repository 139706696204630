import React, { useState } from "react";
import { ContextState, useItemData } from "./PortfolioContext";

export default function Projects() {
  const [isActive, setActive] = useState(-1);
  const data: ContextState = useItemData();
  return (
    <div className="projects-container">
      {data.value?.map((item, index) => {
        return (
          <div key={index}>
            <button
              className="accordion"
              onClick={() => {
                if (isActive === index) setActive(-1);
                else setActive(index);
              }}
            >
              <p className="name">{item.title}</p>
              {item.startEndDate.length > 0 ? (
                <div className="date-container">
                  <p className="date">{`${item.startEndDate[0].toLocaleString(
                    "en-us",
                    { month: "long", year: "numeric" }
                  )}`}</p>
                  <p className="date">
                    to{" "}
                    {item.startEndDate[1] || !item.featured
                      ? `${item.startEndDate[1].toLocaleString("en-us", {
                          month: "long",
                          year: "numeric",
                        })}`
                      : "Present"}
                  </p>
                </div>
              ) : null}
            </button>
            <div className={isActive === index ? "panel active" : "panel"}>
              <p>{item.excerpt}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
